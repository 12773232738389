<template>
  <div class="contact-inner-wrapper block cursor-pointer">
    <HeaderNaviItem :label="$t('navi.contact')" :icon="'contact'" />
  </div>
</template>

<script setup></script>

<style lang="scss">
.contact-inner-wrapper {
  text-decoration: none;
}
</style>
