<template>
  <div>
    <button
      :class="[
        'cookie-button absolute bottom-1 left-1',
        { 'cookie-button--event': eventMode },
      ]"
      @click="showGDPRModal"
    >
      <svg
        class="scale-110"
        style="width: 24px; height: 24px"
        viewBox="0 0 24 24"
      >
        <path
          fill="white"
          d="M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A9,9 0 0,0 21,12C21,11.5 20.96,11 20.87,10.5C20.6,10 20,10 20,10H18V9C18,8 17,8 17,8H15V7C15,6 14,6 14,6H13V4C13,3 12,3 12,3M9.5,6A1.5,1.5 0 0,1 11,7.5A1.5,1.5 0 0,1 9.5,9A1.5,1.5 0 0,1 8,7.5A1.5,1.5 0 0,1 9.5,6M6.5,10A1.5,1.5 0 0,1 8,11.5A1.5,1.5 0 0,1 6.5,13A1.5,1.5 0 0,1 5,11.5A1.5,1.5 0 0,1 6.5,10M11.5,11A1.5,1.5 0 0,1 13,12.5A1.5,1.5 0 0,1 11.5,14A1.5,1.5 0 0,1 10,12.5A1.5,1.5 0 0,1 11.5,11M16.5,13A1.5,1.5 0 0,1 18,14.5A1.5,1.5 0 0,1 16.5,16H16.5A1.5,1.5 0 0,1 15,14.5H15A1.5,1.5 0 0,1 16.5,13M11,16A1.5,1.5 0 0,1 12.5,17.5A1.5,1.5 0 0,1 11,19A1.5,1.5 0 0,1 9.5,17.5A1.5,1.5 0 0,1 11,16Z"
        />
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    eventMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showGDPRModal() {
      window.ppms.cm.api("openConsentForm");
    },
  },
};
</script>
<style lang="scss" scoped>
.cookie-button {
  width: 32px;
  height: 32px;
  bottom: 1rem;
  left: 1rem;
  outline: none;
}

.cookie-button--event {
  bottom: 25px;
  z-index: 1;
}

@media only screen and (max-width: 820px) {
  .cookie-button--event {
    bottom: 5px;
    left: 13px;
  }
}

@media only screen and (max-width: 768px) {
  .cookie-button {
    bottom: 1rem;
    left: 1rem;
  }
  .cookie-button--event {
    bottom: 5px;
    left: 13px;
  }
}
</style>
