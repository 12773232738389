<template>
  <div class="logo-wrapper">
    <nuxt-link :to="currentHomeUrl" class="logo-wrapper flex flex-col group">
      <div class="bg-white h-8">
        <img
          class="logo-img h-8 w-auto"
          src="@/assets/img/Metso_Logo_Black_CMYK.svg"
        />
      </div>
    </nuxt-link>
    <div
      v-if="isValueIncluded"
      tabindex="0"
      class="trackmenu logo-wrapper font-bold"
    >
      <div class="p-2 h-8">
        {{ currentSeries.name }} <span class="arrow is-bottom"></span>
      </div>
      <div class="menu-dropdown">
        <a :href="theUrl" class="p-2">{{
          otherSeries.name
        }}</a>
      </div>
    </div>
    <div v-else tabindex="0" class="trackmenu logo-wrapper font-bold">
      <div class="p-2 h-8">Series <span class="arrow is-bottom"></span></div>
      <div class="menu-dropdown">
        <a v-for="track in tracks" :href="track.url" class="p-2">{{
          track.name
        }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
// Define constants for series names and track identifiers
const SERIES = {
  lokotrack: {
    name: "Lokotrack® Series",
    base: "lokotrack",
  },
  nordtrack: {
    name: "Nordtrack™ Series",
    base: "nordtrack",
  },
};
// Reactive references
const currentSeries = ref(SERIES.nordtrack);
const otherSeries = ref(SERIES.lokotrack);
const theUrl = ref("");
const currentHomeUrl = ref("");

const route = useRoute();
const { locale } = useI18n();
const { $i18n } = useNuxtApp();
const track = useState("track");

// Computed property to generate URLs based on the current language
const generateUrl = (base) => {
  return $i18n.locale.value === "en" ? `/${base}/` : `/${$i18n.locale.value}/${base}/`;
};
// Sets up the current and other series based on the path
const setUpValues = () => {
  const isLokotrack = route.fullPath.includes(SERIES.lokotrack.base);
  track.value = isLokotrack ? SERIES.lokotrack.base : SERIES.nordtrack.base;
  currentSeries.value = isLokotrack ? SERIES.lokotrack : SERIES.nordtrack;
  otherSeries.value = isLokotrack ? SERIES.nordtrack : SERIES.lokotrack;
  theUrl.value = generateUrl(otherSeries.value.base);
};

// Watch for changes in route.path to update values
watch(() => route.path, setUpValues, { immediate: true });
// Wathc for changes in locale
watch(locale, (newLang) => {
  theUrl.value = generateUrl(otherSeries.value.base);
  currentHomeUrl.value = '/' + newLang;
});

const tracks = computed(() => {
  return [
    {
      name: currentSeries.value.name,
      url: generateUrl(currentSeries.value.base),
    },
    { name: otherSeries.value.name, url: generateUrl(otherSeries.value.base) },
  ];
});

// Check if the route includes any of the track base paths
const isValueIncluded = computed(() => {
  return [SERIES.lokotrack.base, SERIES.nordtrack.base].some((base) =>
    route.fullPath.includes(base)
  );
});

onMounted(setUpValues);
</script>

<style lang="scss">
.logo-wrapper {
  width: 170px;
  &.bg-white {
    padding-left: 10px;
  }
}

.trackmenu {
  user-select: none;
  font-size: 16px;
  line-height: 16px;
  position: relative;
  display: inline-block;
  z-index: 2;
  padding: 0;
  margin: 0;
  outline: 0;
  text-align: left;
  border-top: 2px solid #000;
  cursor: pointer;
  .menu-dropdown {
    background-color: #fff;
    width: auto;
    pointer-events: auto;
    position: absolute;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: visibility 1s;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 85% 100%, 0 100%);
    a {
      border: none;
      outline: 0;
      display: block;
      color: #000;
      text-decoration: none;
      width: 170px;
      &:hover {
        color: #00e2b0;
      }
    }
  }
  &:focus {
    pointer-events: none;
    border-top: 2px solid #00e2b0;
    .menu-dropdown {
      opacity: 1;
      visibility: visible;
    }
  }
}

.arrow {
  vertical-align: middle;
  display: inline-block;
  height: 8px;
  position: relative;
  width: 8px;
  margin-left: 5px;
  margin-bottom: 7px;
  &::after {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-right-style: solid;
    border-right-width: 1px;
    content: "";
    display: inline-block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 0;
    width: 8px;
    border-color: #ccc;
  }
  // States
  &.is-top {
    transform: rotate(225deg);
  }
  &.is-bottom {
    transform: rotate(45deg);
  }
}
</style>
