<template>
  <div
    class="my_metso-inner-wrapper block cursor-pointer"
    @click="handleMyMetsoClick"
  >
    <HeaderNaviItem :label="$t('navi.my_metso')" :icon="'my_metso'"></HeaderNaviItem>
  </div>
</template>

<script setup>
const { $bootstrap } = useNuxtApp();
const handleMyMetsoClick = () => {
  const modalElement = document.getElementById("my-metso-modal");
  if (modalElement) {
    const modal = $bootstrap.Modal.getOrCreateInstance(modalElement);
    modal.show();
  }
};
</script>

<style lang="css">
.my_metso-inner-wrapper {
  text-decoration: none;
}
</style>
