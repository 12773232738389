<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 0V4H5V1H13V14H5V11H4V15H14V0H4Z" fill="fill-current" />
    <path
      d="M7.7 9.60001L8.4 10.3L11.2 7.50001L8.4 4.60001L7.6 5.30001L9.3 7.00001H4V8.00001H9.3L7.7 9.60001ZM0 7.00001H1V8.00001H0V7.00001ZM2 7.00001H3V8.00001H2V7.00001Z"
      fill="fill-current"
    />
  </svg>
</template>
