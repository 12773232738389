<template>
  <div
    :class="mobileNaviOpen ? 'min-h-screen' : 'h-16'"
    class="absolute left-0 top-0 w-full md:h-16 text-white bg-black"
  >
    <div class="flex flex-col">
      <HeaderStatusMessage v-if="streamMode && !registerMode" />
      <div
        class="header-content relative bg-black"
        :class="mobileNaviOpen ? 'mobile-navi-open' : ''"
      >
        <div class="logo-wrapper w-full md:w-auto">
          <HeaderLogo v-if="!registerMode" @gohome="goBackHome" />
          <div v-else class="series">Lokotrack™ Series</div>
        </div>
        <div class="navi-wrapper grow-0 md:grow">
          <HeaderNavi v-if="showNavi && isValueIncluded" />
        </div>
        <div v-if="!streamMode" class="my-metso-wrapper w-full md:w-auto">
          <HeaderMyMetso @click="handleMyMetsoClick" />
        </div>
        <div v-if="!streamMode" class="contact-wrapper w-full md:w-auto">
          <HeaderContact @click="handleContactClick" />
        </div>
        <div
          v-if="registerMode"
          class="contact-wrapper absolute right-0 top-0 p-2 md:p-0 w-auto"
        >
          <a href="https://www.mogroup.com/contact/" target="_blank">
            <HeaderContact />
          </a>
        </div>
        <div
          v-if="!streamMode"
          class="lang-wrapper w-full md:w-auto"
          :class="streamMode ? 'lang-wrapper-right' : ''"
        >
          <HeaderLangSelection />
        </div>
        <div
          v-if="showNavi"
          class="mobile-burger-wrapper flex md:hidden absolute top-0 right-0"
        >
          <button
            class="hamburger hamburger--slider focus:outline-none"
            :class="{ 'is-active': mobileNaviOpen }"
            type="button"
            @click="mobileNaviOpen = !mobileNaviOpen"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const { $bootstrap } = useNuxtApp();

defineProps({
  showNavi: {
    type: Boolean,
    default: true,
  },
  streamMode: {
    type: Boolean,
    default: false,
  },
  showStatusMessage: {
    type: Boolean,
    default: false,
  },
  registerMode: {
    type: Boolean,
    default: false,
  },
});

const mobileNaviOpen = ref(false);
const fromRoutePath = ref("");
const route = useRoute();

const isValueIncluded = computed(() => {
  const valuesToCheck = ["lokotrack", "nordtrack"];
  return valuesToCheck.some((val) => route.fullPath.includes(val));
});

watch(route, (to, from) => {
  fromRoutePath.value = from.path;
  if (from.path !== to.path) {
    closeMobileNavi();
  }
});

const handleContactClick = () => {
  const modalElement = document.getElementById("contact-modal");
  if (modalElement) {
    const modal = $bootstrap.Modal.getOrCreateInstance(modalElement);
    modal.show();
  }
  closeMobileNavi();
};

const handleMyMetsoClick = () => {
  const modalElement = document.getElementById("my-metso-modal");
  if (modalElement) {
    const modal = $bootstrap.Modal.getOrCreateInstance(modalElement);
    modal.show();
  }
  closeMobileNavi();
};

const closeMobileNavi = () => {
  mobileNaviOpen.value = false;
};

const goBackHome = () => {
  emit("gohome");
};
</script>

<style lang="scss" scoped>
.series {
  padding: 16px;
  width: 200px;
  height: 64px;
  line-height: 30px;
}
.header-content {
  z-index: 99;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.lang-wrapper-right {
  position: absolute;
  right: 0;
}
/* .mobile-burger-wrapper {
  height: 100%;
} */
@media only screen and (max-width: 767px) {
  .header-content {
    flex-direction: column;
    flex-wrap: wrap;
    .navi-wrapper {
      padding-top: 1rem;
    }
    .navi-wrapper,
    .my-metso-wrapper,
    .contact-wrapper,
    .lang-wrapper {
      display: none;
    }
    &.mobile-navi-open {
      min-height: 100vh;
      .navi-wrapper,
      .my-metso-wrapper,
      .contact-wrapper,
      .lang-wrapper {
        display: block;
      }
    }
  }
}
</style>
