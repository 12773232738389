<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3V14H14V13H2V4.707L6.746 9.453C6.97745 9.6862 7.25293 9.87109 7.55644 9.99693C7.85995 10.1228 8.18544 10.187 8.514 10.186H8.515C9.182 10.186 9.81 9.926 10.284 9.453L15 4.728V11.97H16V3H1ZM9.575 8.748C9.292 9.03 8.915 9.187 8.515 9.187H8.514C8.114 9.187 7.737 9.031 7.454 8.747L2.707 4H14.314L9.575 8.748ZM15 13H16V14H15V13Z"
      fill="fill-current"
    />
  </svg>
</template>

<script>
export default {
  name: "ContactIcon",
};
</script>
