<template>
  <div
    class="products-list relative md:absolute left-0 top-0 md:mt-16 py-2 bg-white border-corner-b-r text-left normal-case"
  >
    <div
      v-for="(cat, index) in settings.products_list"
      :id="'products-title-' + cat._uid"
      :key="(cat._uid, index)"
      class="mx-4 my-2 text-lg md:text-sm"
    >
      <p class="prod-title font-bold text-brand-black mt-2 mb-2">
        {{ cat.text }}
      </p>
      <ul class="prod-list">
        <li
          v-for="(item, index2) in cat.products"
          :id="'prod-' + item._uid"
          :key="(item._uid, index2)"
          class="prod-item"
        >
          <nuxt-link
            :to="makeLink(item)"
            :class="{
              'text-brand-green':
                currentRoutePath === item.link.cached_url ||
                currentRoutePath === '/' + item.link.cached_url,
            }"
            class="w-full block text-brand-black hover:text-brand-green py-1 md:py-0"
          >
            <span class="text-content"
              >{{ item.text }}<span v-if="item.has_360"> (3D)</span></span
            >
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
const { settingsData } = useSettings();
const settings = settingsData.value;
const route = useRoute();
const currentRoutePath = computed(() => route.path);

const makeLink = (item) => {
  if (item?.link?.linktype === "url") {
    return item.link.cached_url;
  } else {
    return item.link.cached_url.indexOf("/") === 0
      ? item.link.cached_url
      : `/${item.link.cached_url}`;
  }
};
</script>

<style lang="scss">
.products-list {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.prod-item .text-content {
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .products-list {
    width: 100%;
    white-space: normal;
  }
}
</style>
