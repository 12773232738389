<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 1C4.364 1 1 4.364 1 8.5C1 12.636 4.364 16 8.5 16C12.636 16 16 12.636 16 8.5C16 4.364 12.636 1 8.5 1ZM15 8.5C15 9.311 14.844 10.085 14.571 10.802C10.973 11.457 9.909 10.984 9.603 10.634C9.221 10.197 9.51 9.333 9.741 8.639C9.886 8.207 10.01 7.835 10.01 7.496C9.994 6.919 9.639 6.483 9.326 6.1C8.82 5.479 8.73 5.332 9.229 4.886C9.314 4.824 9.673 4.828 9.89 4.831C10.365 4.835 10.911 4.84 11.306 4.517C11.811 4.103 11.679 3.428 11.572 2.885L11.549 2.763C12.5902 3.31665 13.4614 4.14292 14.0692 5.15345C14.6771 6.16398 14.9988 7.32074 15 8.5ZM2.461 6.109C2.665 6.024 2.841 5.98 2.935 6.018C3.14 6.102 3.295 6.586 3.421 6.976C3.511 7.256 3.604 7.545 3.735 7.8C3.933 8.183 4.283 8.43 4.622 8.669C5.311 9.156 5.907 9.576 5.395 11.153C5.22 11.685 5.22 11.685 4.352 11.526C3.933 11.449 3.352 11.351 2.657 11.33C2.22567 10.4489 2.00097 9.48102 2 8.5C2 7.655 2.167 6.85 2.461 6.109ZM8.5 15C7.48735 14.999 6.489 14.761 5.58483 14.305C4.68066 13.849 3.89578 13.1877 3.293 12.374C3.624 12.413 3.922 12.463 4.173 12.509C4.458 12.562 4.738 12.613 5.003 12.613C5.558 12.613 6.041 12.386 6.346 11.465C7.087 9.184 5.951 8.382 5.199 7.852C4.947 7.674 4.71 7.506 4.625 7.34C4.523 7.145 4.45 6.907 4.373 6.669C4.174 6.052 3.95 5.354 3.315 5.093C3.22035 5.05613 3.12166 5.03062 3.021 5.017C3.60663 4.09372 4.4158 3.33316 5.37355 2.80578C6.3313 2.2784 7.40665 2.00125 8.5 2C9.198 2 9.869 2.113 10.499 2.318C10.5039 2.57379 10.5348 2.82842 10.591 3.078C10.631 3.282 10.705 3.661 10.672 3.744C10.558 3.837 10.175 3.834 9.9 3.831C9.427 3.828 8.924 3.821 8.567 4.137C7.333 5.238 8.095 6.173 8.551 6.731C8.784 7.017 9.004 7.287 9.01 7.51C9.01 7.673 8.9 8.003 8.793 8.322C8.497 9.209 8.091 10.424 8.852 11.293C9.344 11.855 10.231 12.133 11.576 12.133C12.267 12.133 13.081 12.059 14.023 11.912C13.4417 12.8546 12.6292 13.633 11.6626 14.1734C10.696 14.7139 9.60741 14.9984 8.5 15Z"
      fill="fill-current"
    />
  </svg>
</template>

<script>
export default {
  name: "LanguageIcon",
};
</script>
