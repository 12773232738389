<template>
  <transition name="fadeHeight" mode="out-in">
    <div
      v-show="activeStatus && showStatus"
      class="message-wrapper sticky top-0 w-full h-12 bg-brand-red flex flex-row justify-between items-center px-4"
    >
      <div
        class="message-content w-full items-center content-center text-center justify-center"
      >
        <span>
          {{ getContent }}
        </span>
      </div>
      <button class="close-button p-1 outline-none" @click="showStatus = false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showStatus: true,
      error: null,
    };
  },
  computed: {
    activeStatus() {
      return this.error !== null && this.error.data().active;
    },
    getContent() {
      if (this.error === null) {
        return "";
      }

      if (this.error.data().customMessage !== "") {
        return this.error.data().customMessage;
      } else {
        return this.$t("errormessages." + this.error.data().message);
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$fire.firestore
        .collection("config")
        .doc("error")
        .onSnapshot({ includeMetadataChanges: true }, (message) => {
          this.error = message;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.message-wrapper {
  z-index: 99999;
}
.close-button {
  border-radius: 50%;
  background: transparent;
  transition: 0.1s all ease-out;
}
.close-button:hover {
  background: rgba(0, 0, 0, 0.5);
  transition: 0.1s all ease-out;
}
.close-button svg {
  fill: white;
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 100px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
