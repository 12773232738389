<template>
  <div
    class="navi-item group"
    :class="[{ 'navi-active': naviActive, 'prod-active': prodActive }]"
  >
    <div
      v-if="iconComponent"
      class="navi-item-icon pr-2 fill-current text-white group-hover:text-brand-black"
      :class="[iconPaddingClass]"
    >
      <component :is="iconComponent" />
    </div>
    <span class="text-content">{{ label }}</span>
  </div>
</template>

<script setup>
import ContactIcon from "@/components/icons/ContactIcon.vue";
import LanguageIcon from "@/components/icons/LanguageIcon.vue";
import MyMetsoIcon from "@/components/icons/MyMetsoIcon.vue";

const { label, naviActive, prodActive, icon } = defineProps({
  label: {
    type: String,
    required: true,
  },
  naviActive: {
    type: Boolean,
    default: false,
  },
  prodActive: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
});

const iconComponents = {
  my_metso: MyMetsoIcon,
  contact: ContactIcon,
  language: LanguageIcon,
};

const iconComponent = computed(() => iconComponents[icon]);
const iconPaddingClass = computed(() =>
  icon === "language" ? "p-0 md:px-4" : "pr-2"
);
</script>

<style lang="scss" scoped>
.navi-item {
  @apply h-10;
  @apply md:h-16;
  @apply px-4;
  @apply md:px-1;
  @apply lg:px-2;
  @apply xl:px-4;
  @apply text-left;
  @apply md:text-center;
  @apply text-lg;
  @apply md:text-xs;
  @apply lg:text-sm;
  @apply uppercase;
  @apply leading-tight;
  @apply text-white;
  @apply bg-black;
  @apply hover:bg-brand-green;
  @apply hover:text-brand-black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  background-repeat: no-repeat;
  background-position: center top;
  &.navi-active {
    background-image: url("@/assets/img/icons/icon_navi_selected.png");
  }
  &.prod-active {
    background-color: white;
    color: black;
    border-top: 2px solid #00e2b0;
  }
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
    background-position: left center;
    &.navi-active {
      background-image: url("@/assets/img/icons/icon_navi_selected_mobile.png");
    }
  }
}
.text-content,
.navi-item-icon {
  pointer-events: none;
}
</style>
