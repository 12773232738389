<template>
  <div class="navi-content">
    <ul
      class="navi-list mb-0 w-full flex flex-col md:flex-row flex-wrap md:flex-nowrap justify-center align-center"
      v-if="mainNavigation"
    >
      <li
        v-for="(item, index) in mainNavigation"
        :id="'navi-' + item._uid"
        :key="(item._uid, index)"
        class="navi-listitem block w-full md:w-auto flex-auto md:grow-0"
      >
        <NuxtLink
          v-if="item.link?.id !== ''"
          class="block"
          role="button"
          :to="makeLink(item)"
        >
          <HeaderNaviItem
            :label="item.text"
            :navi-active="currentRoutePath.includes(item.link.cached_url)"
          />
        </NuxtLink>
        <div
          v-else-if="item.link.id === ''"
          ref="prodnavi"
          class="relative block cursor-pointer"
          @click="handleProductsClick()"
        >
          <HeaderNaviItem
            :label="item.text"
            :navi-active="currentRoutePath.includes('products')"
            :prod-active="prodListOpen"
            class="prod-navi-item"
          />
          <HeaderProductsList v-if="prodListOpen" ref="prodlist" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
const mainNavigation = ref(null);
const prodListOpen = ref(false);
const prodnavi = ref(null);
const prodlist = ref(null);
const route = useRoute();
const currentRoutePath = computed(() => route.path);

const { locale } = useI18n();

const fetchSettings = () => {
  const { settingsData } = useSettings();
  mainNavigation.value = settingsData?.value?.main_navigation;
};

const makeLink = (item) => {
  if (item?.link?.linktype === "url") {
    return item.link.cached_url;
  } else {
    return item.link.cached_url.indexOf("/") === 0
      ? item.link.cached_url
      : `/${item.link.cached_url}`;
  }
};

// Re-fetch settings data when the locale changes
watch(locale, () => {
  fetchSettings();
});

// Use onMounted to perform async operations
onMounted(() => {
  fetchSettings();
});

onBeforeMount(() => {
  window.document.addEventListener(
    "click",
    (e) => {
      handleDocumentClick(e);
    },
    false
  );
});
onBeforeUnmount(() => {
  window.document.removeEventListener(
    "click",
    (e) => {
      handleDocumentClick(e);
    },
    false
  );
});

const handleProductsClick = () => {
  prodListOpen.value = !prodListOpen.value;
};

const handleDocumentClick = (e) => {
  if (
    e.target !== prodnavi.value &&
    !e.target.classList.contains("prod-navi-item") &&
    e.target !== prodlist.value &&
    !e.target.classList.contains("prod-item")
  ) {
    prodListOpen.value = false;
  }
};
</script>

<style lang="scss">
.navi-content ul {
  list-style: none;
  padding-left: 0;
  .navi-list a {
    text-decoration: none;
  }
}
</style>
