<template>
  <div
    ref="langnavi"
    class="lang-navi relative block cursor-pointer"
    @click="handleLangClick()"
  >
    <HeaderNaviItem :label="''" :icon="'language'" class="lang-navi-item" />
    <ul
      v-show="langListOpen"
      class="lang-block relative md:absolute right-0 top-0 md:mt-16 bg-brand-black text-right"
    >
      <li
        v-for="lang in availableLangs"
        :id="'lang-' + lang.code"
        :key="lang.code"
        class="lang-list-item"
      >
        <NuxtLink
          :to="switchLocalePath(lang.code)"
          class="navi-item block md:text-center text-lg md:text-sm uppercase leading-tight text-white bg-black py-2 px-4 md:px-8"
          :class="{
            'text-brand-green': isActive(lang.code),
          }"
        >
          {{ lang.name }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script setup>
const { locale, locales } = useI18n();
const { settingsData } = useSettings();
const selectedLanguages = settingsData.value.language_selection;

const switchLocalePath = useSwitchLocalePath();
const availableLangs = computed(() => {
  if (selectedLanguages) {
    return locales.value.filter((x) => {
      return selectedLanguages.some((t) => t === x.code || x.code === "en");
    });
  } else {
    return locales.value;
  }
});
const langListOpen = ref(false);

const isActive = (lang) => {
  return lang.code === locale;
};

function handleLangClick() {
  langListOpen.value = !langListOpen.value;
}
</script>

<style lang="scss">
.lang-navi ul {
  list-style: none;
  padding-left: 0;
  .navi-item:hover {
    @apply text-brand-black #{!important};
    @apply bg-brand-green #{!important};
  }
}
.router-link-active {
  @apply text-brand-green #{!important};
}
</style>
